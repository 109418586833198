<template>
  <b-progress
    :max="numAll"
    animated
  >
    <b-progress-bar
      :value="numSolved"
      :label="`${((numSolved / numAll) * 100).toFixed(2)}%`"
      :class="getProgressClass()"
    />
  </b-progress>
</template>

<script>
export default {
  name: "RMProgressBar",
  props: {
    numSolved: {
      type: Number,
      required: true,
    },
    numAll: {
      type: Number,
      required: true
    }
  },
  methods: {
    getProgressClass() {
      if(this.numAll === 0) {
        return "primary";
      }

      const percentageDone = this.numSolved / this.numAll;
      if (percentageDone < 0.25) {
        return "rm-wrong-with-color";
      }
      if (percentageDone < 0.50) {
        return "rm-selected-with-color";
      }
      if (percentageDone < 0.75) {
        return "rm-blue-with-color";
      }
      if (percentageDone < 1) {
        return "rm-solved-sec-with-color";
      }
      return "rm-solved-with-color";
    },
  }
}
</script>