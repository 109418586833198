<template>
  <b-tab>
    <template v-slot:title>
      <span class="d-sm-none">{{ $t("mentorship.results") }}</span>
      <span class="d-sm-block d-none">{{ $t("mentorship.results") }}</span>
    </template>
    <hr/>
    <div>
      <b-table striped hover :items="results" :fields="fields">
        <template #thead-top>
          <b-tr>
            <b-th colspan="2"></b-th>
            <b-th :colspan="numTasks">{{ $t("assignments.tasks") }}</b-th>
          </b-tr>
        </template>
        <template #cell(result)="data">
          {{ data["item"]["percentageCorrect"] }} %
        </template>
        <template #cell(name)="data">
          <span v-if="!data['item']['active']">
            <s>{{ data["item"]["name"] }}</s> <small>({{ $t("mentorship.exMember") }})</small>
          </span>
          <span v-else>
            {{ data["item"]["name"] }}
          </span>
        </template>
        <template #cell()="data">
          <i
            v-if="getTask(data).s && getTask(data).c"
            class="fa fa-check fa-solved-rm" aria-hidden="true"
          />
          <i
            v-else-if="getTask(data).s && !getTask(data).c"
            class="fa fa-times fa-wrong-rm" aria-hidden="true"
          />
          <i
            v-else
            class="fa fa-question fa-default-rm" aria-hidden="true"
          />
        </template>
      </b-table>
    </div>
  </b-tab>
</template>

<script>
export default {
  name: "AssignmentStudentsMentor",
  props: {
    results: {
      type: Array,
      required: true,
    },
    numTasks: {
      type: Number,
      required: true,
    }
  },
  computed: {
    fields() {
      const basicFields = [
        {
          key: 'name',
          label: this.$t("mentorship.student")
        },
        {
          key: 'result',
          label: this.$t("mentorship.result"),
        },
      ];
      for (let i = 0; i< this.numTasks; i++){
        basicFields.push({
          key: `task-${i}`,
          label: (i + 1).toString(),
        })
      }
      return basicFields;
    },
  },
  methods: {
    getTask(data) {
      return data['item']['tasks'][parseInt(data['field']['key'].split('-')[1])]
    }
  }
}
</script>