import {getMyStudentRoute, getStudentGroupRoute} from "@/_helpers/routes_helper";

export const getEntityFromPath = (vueInstance) => vueInstance.$route.path.split("/")[1];
export const getEntityIdFromPath = (vueInstance) => vueInstance.$route.path.split("/")[2];

export const navigateToEntityPage = (vueInstance) => {
  const entity = getEntityFromPath(vueInstance);
  const entityId = getEntityIdFromPath(vueInstance);

  if(entity === "studentGroups") {
    vueInstance.$router.push(getStudentGroupRoute(entityId));
  }
  if(entity === "myStudents") {
    vueInstance.$router.push(getMyStudentRoute(entityId));
  }
}