<template>
  <b-tab>
    <template v-slot:title>
      <span class="d-sm-none">{{ $t("assignments.tasks") }}</span>
      <span class="d-sm-block d-none">{{ $t("assignments.tasks") }}</span>
    </template>
    <hr/>
    <div class="row">
      <div class="col-xs-12 col-sm-6 col-md-6 col-xl-4 col-xxl-3 col-xxxl-3"
        v-for="(task, idx) in tasks"
        :key="idx">
        <RMPanel :title="getTaskTitle(task, idx)">
          <template v-slot:panelBody>
            <div class="d-flex justify-content-between m-b-10">
              <span>{{ capitalize($t("games.common.task")) }}:</span>
              <div v-html="renderTask(task.task)"></div>
            </div>
            <hr/>
            <div class="d-flex justify-content-between m-b-10">
              <span>{{ capitalize($t("games.common.correctAnswer")) }}:</span>
              <div class="d-flex justify-content-end">
                <div class="align-self-center">
                  <b-badge :id="getTooltipName(idx, 'correct')" tag="div" class="m-r-15 rm-solved-with-color" v-if="task.studentAnswers[task.answers.correct].length > 0">
                    {{ task.studentAnswers[task.answers.correct].length }}
                  </b-badge>
                  <b-tooltip :target="getTooltipName(idx, 'correct')" triggers="hover" v-if="task.studentAnswers[task.answers.correct].length > 0">
                    {{ getTooltipContent(task.studentAnswers[task.answers.correct]) }}
                  </b-tooltip>
                </div>
                <div v-html="renderTask(task.answers.correct)"></div>
              </div>
            </div>
            <hr/>
            <div class="d-flex justify-content-between">
              <div class="w-50">{{ capitalize($t("games.common.wrongAnswers")) }}:</div>
              <div class="w-50">
                <div
                  v-for="(wa, idy) in task.answers.wrong"
                  :key="idy"
                  class="d-flex justify-content-end m-b-15"
                >
                  <div class="align-self-center">
                    <b-badge :id="getTooltipName(idx, idy)" tag="div" class="m-r-15 rm-wrong-with-color" v-if="task.studentAnswers[wa].length > 0">
                      {{ task.studentAnswers[wa].length }}
                    </b-badge>
                    <b-tooltip :target="getTooltipName(idx, idy)" triggers="hover" v-if="task.studentAnswers[wa].length > 0">
                      {{ getTooltipContent(task.studentAnswers[wa]) }}
                    </b-tooltip>
                  </div>
                  <div v-html="renderTask(wa)"/>
                </div>
              </div>
            </div>
            <hr/>
            <div class="d-flex justify-content-between">
              <div>{{ $t("assignments.numberOfStudentsAnswered") }}:</div>
              <div class="width-100 text-right"><b>{{ task.numAnswered }} / {{ numAllGroupMembers }}</b></div>
            </div>
            <div class="m-t-15">
              <RMProgressBar :num-all="numAllGroupMembers" :num-solved="task.numAnswered"/>
            </div>
          </template>
        </RMPanel>
      </div>
    </div>
  </b-tab>
</template>

<script>

import katex from 'katex';
import 'katex/dist/katex.min.css';
import RMPanel from "@/components/common/RMPanel.vue";
import RMProgressBar from "@/components/common/RMProgressBar.vue"; // Import the KaTeX CSS for styling

export default {
  name: "AssignmentTasksMentor",
  components: {RMProgressBar, RMPanel},
  props: {
    numAllGroupMembers: {
      type: Number,
      required: true
    },
    tasks: {
      type: Array,
      required: true
    }
  },
  methods: {
    getTooltipName(idx, idy) {
      return `tooltip-answer-${idx}-${idy};`
    },
    getTooltipContent(wrongAnswer) {
      const names = wrongAnswer.map(x => x.name);
      return names.join(", ");
    },
    getTaskTitle(task, idx) {
      return `${idx + 1}. ${this.$t("games.games." + task.game + ".name")}`
    },
    renderTask(value) {
      try {
        return katex.renderToString(value, {output: "html"});
      } catch (error) {
        return value;
      }
    },
    capitalize(text) {
      return text.charAt(0).toUpperCase() + text.slice(1).toLowerCase();
    }
  }
}
</script>