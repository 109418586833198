<template>
  <div>
    <RMPageHeader>
      <template v-slot:header v-if="assignment">
        {{ assignment.name }}
        <small>
          <b-link v-if="entityData" v-on:click="navigateToEntityPage()">({{ entityData.name }})</b-link>
        </small>
      </template>
    </RMPageHeader>
    <b-tabs nav-class="mb-3" pills v-if="assignment">
      <AssignmentTasksMentor :tasks="assignment.tasks" :num-all-group-members="assignment.numGroupMembers"/>
      <AssignmentStudentsMentor :results="assignment.results" :num-tasks="assignment.tasks.length"/>
    </b-tabs>
  </div>
</template>

<script>

import RMPageHeader from "@/components/common/RMPageHeader.vue";
import {assignmentService} from "@/_services/assignment.service";
import {mentorshipService} from "@/_services/mentorship.service";
import AssignmentTasksMentor from "@/pages/mentorship/assignments/assignment/AssignmentTasksMentor.vue";
import AssignmentStudentsMentor from "@/pages/mentorship/assignments/assignment/AssignmentStudentsMentor.vue";
import {getEntityFromPath, getEntityIdFromPath, navigateToEntityPage} from "@/_helpers/vue.helper";

export default {
  name: "Assignment",
  components: {AssignmentStudentsMentor, AssignmentTasksMentor, RMPageHeader},
  data() {
    return {
      loadingAssignment: false,
      entityLoading: false,
      entityData: null,
      assignment: null,
    }
  },
  computed: {
    entity() {
      return getEntityFromPath(this);
    },
    entityId() {
      return getEntityIdFromPath(this);
    },
  },
  methods: {
    navigateToEntityPage() {
      return navigateToEntityPage(this);
    },
    loadAssignment() {
      this.loadingAssignment = true;

      assignmentService.getAssignment(this.$route.params.assignmentId).then((data) => {
        this.assignment = data.assignment;
        this.loadingAssignment = false;
      })
    },
    loadStudentGroup() {
      this.entityLoading = true;

      mentorshipService.getStudentGroup(this.$route.params.studentGroupId).then((data) => {
        this.entityData = data.data;
        this.entityLoading = false;
      })
    },
    loadMyStudent() {
      this.entityLoading = true;

      mentorshipService.getMyStudent(this.entityId).then((data) => {
        this.entityData = data.data;
        this.entityLoading = false;
      }).catch((error) => {
        this.error = error;
      })
    },
  },
  created() {
    this.loadAssignment();
    if(this.entity === "studentGroups"){
      this.loadStudentGroup();
    }
    if(this.entity === "myStudents"){
      this.loadMyStudent();
    }
  }
}
</script>